import { MouseEventHandler, useState } from "react"

import { AlertDialog } from "components/AlertDialog"
import { DataTable } from "components/DataTable"
import Loader from "components/Loader"
import { Sheet } from "components/Sheet"

import { useBanUser } from "modules/users/hooks/useBanUser"
import { useUsersList } from "modules/users/hooks/useUsersList"

import UserForm from "./UsersForm"
import { User } from "modules/users/types"
import { createUserColumns } from "./Columns"
import { TableActionsUser } from "components/TableActionsUser"

const Users = () => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isSheetOpen, setSheetOpen] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  // const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState<string>("")
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const {
    data: usersList,
    // paginationInfo,
    isLoading,
  } = useUsersList(searchTerm);


  const { triggerUserBan } = useBanUser(user?._id ?? "")

  const getRowData = (user: User) => {
    setUser(user)
  }

  const columns = createUserColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  })
  
  const handlePress: MouseEventHandler<HTMLButtonElement> = () => {
    if (search.length > 3) {
      setSearchTerm(search);
    } else {
      setSearchTerm(undefined);
    }
  };
  
  return (
    <div>
      <TableActionsUser
        searchValue={search}
        onChangeSearchValue={setSearch}
        isAddButtonHidden={true}
        onPress={handlePress}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={usersList || []} />
          {/* <Pagination
            className="justify-end mt-3"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationInfo={paginationInfo}
          /> */}
        </>
      )}

      <Sheet
        sheetTitle="Foydalanuvchiga titul berish"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}>
        {user && <UserForm user={user} setSheetOpen={setSheetOpen} />}
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz foydalanuvchiga ban berasiz, va o'yinchi foydalanuchilar bilan o'ynashdan mahrum etiladi."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerUserBan}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  )
}

export default Users
