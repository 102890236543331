import {
  Row,
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import { Loader2 } from 'lucide-react';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils/styleUtils';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  navigateTable?: boolean;
  loadingNextPage?: boolean;
  onEndReach?: () => void;
  rowAction?: (data: TData) => void;
  checkRowSelected?: (data: TData) => boolean;
}

const DEFAULT_TABLE_COLUMN_WIDTH = 150;

export function DataTable<TData, TValue>({
  columns,
  data,
  navigateTable,
  onEndReach,
  loadingNextPage,
  rowAction,
  checkRowSelected,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const observerRef = useRef<IntersectionObserver>();
  const lastElementRef = useRef<HTMLTableCellElement | null>(null);

  useEffect(() => {
    if (onEndReach) {
      observerRef.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            onEndReach();
          }
        },
        { threshold: 1 }
      );

      if (lastElementRef.current) {
        observerRef.current.observe(lastElementRef.current);
      }

      return () => {
        if (observerRef.current && lastElementRef.current) {
          observerRef.current.unobserve(lastElementRef.current);
        }
      };
    }
  }, [onEndReach]);

  const navigate = useNavigate();

  const handleRowClick = (row: Row<TData>) => {
    if (navigateTable) {
      navigate(row.getValue('_id'));
      return;
    }

    if (rowAction) {
      rowAction(row.original);
    }
  };

  return (
    <div className="relative rounded-md border dark:text-white overflow-auto max-h-fit h-full scroll">
      <Table>
        <TableHeader className="sticky top-0 bg-secondary">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const styles: CSSProperties =
                  header.getSize() !== DEFAULT_TABLE_COLUMN_WIDTH
                    ? { width: `${header.getSize()}px` }
                    : {};
                return (
                  <TableHead key={header.id} style={styles}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            <>
              {table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cn({
                    'cursor-pointer': navigateTable || rowAction,
                    'text-[#2ba0fc] font-bold': checkRowSelected?.(
                      row.original
                    ),
                  })}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell
                      ref={
                        index + 1 === table.getRowModel().rows.length &&
                        cellIndex === 0
                          ? lastElementRef
                          : null
                      }
                      key={cell.id}
                      className="py-3"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

              {loadingNextPage && (
                <TableRow className="h-10 w-full flex items-center">
                  <Loader2 className="mx-auto text-primary/60 animate-spin" />
                </TableRow>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                Natijalar yo'q
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
