import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import * as LabelPrimitive from "@radix-ui/react-label"
import { Form } from "components/ui/form"
import { Title, User } from "modules/users/types"
import LoadingButton from "components/LoadingButton"
import { useEditUser } from "modules/users/hooks/useEditUser"

// Define the Zod schema for validation
const userSchema = z.object({
  title: z.nativeEnum(Title),
})

type UserFormSchema = z.infer<typeof userSchema>

interface IProps {
  user?: User
  setSheetOpen: (state: boolean) => void
}

// Utility function to get enum key from value
const getEnumKey = (enumObj: any, value: string) => {
  const key = Object.keys(enumObj).find(key => enumObj[key] === value)
  return key || "UNKNOWN"
}

// Utility function to check if a value is a valid Title enum member
const isValidTitle = (title: any): title is Title => {
  return Object.values(Title).includes(title)
}

export default function UserForm({ user, setSheetOpen }: IProps) {
  const { triggerUserEdit, isPending: isUserEditPending } = useEditUser({
    id: user?._id,
    setSheetOpen,
  })

  const form = useForm<UserFormSchema>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      title: user && isValidTitle(user.title) ? user.title : Title.NONE,
    },
  })

  const { control, handleSubmit, formState } = form

  console.log(formState.errors)

  const onSubmit = (formValues: UserFormSchema) => {
    triggerUserEdit(formValues)
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="flex flex-col my-4">
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <RadioGroupPrimitive.Root
                {...field}
                onValueChange={(value) => field.onChange(value)}
                value={field.value}
                id="title-radio-group" 
                className="flex flex-col space-y-2"
              >

                <RadioGroupPrimitive.Item
                  value={Title.NONE}
                  className="flex items-center space-x-2"
                >
                  <RadioGroupPrimitive.Indicator className="w-4 h-4 border rounded-full flex items-center justify-center">
                    {field.value === Title.NONE && (
                      <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                    )}
                  </RadioGroupPrimitive.Indicator>
                  <span>Unvonsiz</span>
                </RadioGroupPrimitive.Item>

                {Object.entries(Title).map(([key, value]) => (
                  key !== "NONE" && (
                    <RadioGroupPrimitive.Item
                      key={value}
                      value={value}
                      className="flex items-center space-x-2"
                    >
                      <RadioGroupPrimitive.Indicator className="w-4 h-4 border rounded-full flex items-center justify-center">
                        {field.value === value && (
                          <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                        )}
                      </RadioGroupPrimitive.Indicator>
                      <span>{key}</span>
                    </RadioGroupPrimitive.Item>
                  )
                ))}
              </RadioGroupPrimitive.Root>
            )}
          />
          {formState.errors.title && (
            <span className="text-red-500 text-sm mt-1">
              {formState.errors.title.message}
            </span>
          )}
        </div>
        <LoadingButton isLoading={isUserEditPending}>
          Saqlash
        </LoadingButton>
      </form>
    </Form>
  )
}
