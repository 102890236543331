import { useMutation } from "@tanstack/react-query"

import { useToast } from "components/ui/use-toast"

import { BanUser } from "../api"

import { queryClient } from "services/react-query"
import { showErrorToast } from "utils/showErrorToast"

export const useBanUser = (id: string) => {
  const { toast } = useToast()

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => BanUser(id),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyatli ban berildi!",
      })
      queryClient.invalidateQueries({ queryKey: ["users_list"] })
    },
    onError: (error: Error) => showErrorToast(error),
  })

  return { triggerUserBan: mutate, isSuccess, isError }
}
