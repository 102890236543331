export interface Course {
  _id: string
  name: string
}

export interface User {
  _id: string
  id: string
  name: string
  photo: string | null
  title: string
  is_banned: boolean
}


export interface UserTitleInput {
  title: Title
}

export enum Title {
  CM = 'cm',
  FM = 'fm',
  IM = 'im',
  GM = 'gm',
  WCM = 'wcm',
  WFM = 'wfm',
  WIM = 'wim',
  WGM = 'wgm',
  UM = 'um', //Uzchess master
  KM = 'km', //Ko'cha master
  DM = 'dm', //Divan master
  NM = 'nm',
  NONE = 'none',
}