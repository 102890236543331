import http from "services/api"
import { UserTitleInput } from "./types"
// import { AssignUserType, UserEditBody, UserInput } from "./types"

export const GetUsersList = async (
  // page: number,
  // title?: string,
  query?: string,
) => {
  return await http.get("/profile/search", {
    params: {
      // page,
      // created_by: title ? title : undefined,
      query: query ? query : undefined,
    },
  })
}

export const BanUser = async (id: string) => {
  return await http.put(`/report/${id}/ban`)
}
  
export const SetTitleUser = async (id: string, title: UserTitleInput) => {
  return await http.post(`/profile/${id}/title`, title)
}