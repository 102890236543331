import { useQuery } from "@tanstack/react-query"
import { get } from "lodash"

import { adaptUsers } from "../adapters"
import { GetUsersList } from "../api"

import { getPagination } from "modules/adapter"

export const useUsersList = (
  // currentPage: number,
  // createdBy?: string,
  query?: string,
) => {
  const initialData = {
    data: adaptUsers(),
    paginationInfo: getPagination(),
  }
  const { data = initialData, ...args } = useQuery({
    queryKey: ["users_list",  query],
    queryFn: () => GetUsersList(query),
    select: (data) => ({
      data: adaptUsers(get(data, "data.data")),
      paginationInfo: getPagination(get(data, "data.pagination")),
    }),
    retry: 0,
  })

  return {
    ...data,
    ...args,
  }
}
