import React, { useEffect, useState } from 'react';
import heic2any from 'heic2any';

interface HEICImageProps {
  src: string;
  alt?: string;
  style?: React.CSSProperties;
}

const HEICImage: React.FC<HEICImageProps> = ({ src, alt = 'Image', style = {} }) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true; 
    let objectUrl: string | null = null; 

    const processImage = async () => {
      if (src.toLowerCase().endsWith('.heic')) {
        try {
          const response = await fetch(src);
          if (!response.ok) throw new Error('Network response was not ok');
          const heicBlob = await response.blob();

          
          const converted = await heic2any({
            blob: heicBlob,
            toType: 'image/jpeg',
            quality: 0.3, 
          });

          
          const convertedBlob = Array.isArray(converted) ? converted[0] : converted;

          
          objectUrl = URL.createObjectURL(convertedBlob);
          if (isMounted) setImageSrc(objectUrl);
        } catch (err) {
        //   console.error('Error converting HEIC image:', err);
          if (isMounted) setError(true);
        } finally {
          if (isMounted) setLoading(false);
        }
      } else {
        
        if (isMounted) {
          setImageSrc(src);
          setLoading(false);
        }
      }
    };

    processImage();

    
    return () => {
      isMounted = false;
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [src]);

  if (loading) return <div>Yuklanmoqda ...</div>;
  if (error) return <div>Xatolik</div>;

  return <img src={imageSrc} alt={alt} style={style} />;
};

export default HEICImage;
