import { ColumnDef } from "@tanstack/react-table"
import { DataTableRowActionsUser } from "components/DataTableRowActionsUser"
import HEICImage from "components/HeicImage"
import { User } from "modules/users/types"
import normalizeImgUrl from "utils/normalizeFileUrl"

interface IProps {
  getRowData: (user: User) => void
  setSheetOpen: (state: boolean) => void
  setDialogOpen: (state: boolean) => void
}

export const createUserColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<User>[] => [

    {
      accessorKey: 'photo',
      header: 'Rasmi',
      cell: ({ row }) =>{
        const imageUrl = row.getValue<string>('photo');    
        return imageUrl ? (
          <HEICImage
            src={normalizeImgUrl(imageUrl)}
            alt="img"
            style={{
              width: 75,
              height: 75,
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        ) : (
          <span>Rasm yo'q</span>
        );
      }      
    },
    {
      accessorKey: "name",
      header: "Ism",
      cell: ({ row }) => <span>{row.original.name}</span>,
    },
    {
      accessorKey: "title",
      header: "Unvoni",
      cell: ({ row }) => <span>{row.original.title==="none"? "Unvoni yo'q":row.original.title.toUpperCase()}</span>,
    },
    {
      accessorKey: "is_banned",
      header: "Ban Holati",
      cell: ({ row }) => (
        <span
          className={
            row.original.is_banned
              ? "text-red-500 font-semibold"
              : "text-green-500 font-semibold"
          }
        >
          {row.original.is_banned ? "Banga mahkum qilingan" : "Toza"}
        </span>
      ),
    },
    {
      accessorKey: "_id",
      header: () => <span className="sr-only">Actions</span>,
      size: 50,
      cell: ({ row }) => (
        <DataTableRowActionsUser
          row={row}
          getRowData={getRowData}
          setDialogOpen={setDialogOpen}
          setSheetOpen={setSheetOpen}
        />
      ),
    },
  ]
