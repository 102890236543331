import { MouseEventHandler, ReactNode, useState } from "react"

import { Plus, Search } from "lucide-react"

import { Sheet } from "./Sheet"
import { Button } from "./ui/button"
import { Input } from "./ui/input"

interface IProps {
  searchValue?: string
  onChangeSearchValue?: (value: string) => void
  sheetTriggerTitle?: string
  sheetTitle?: string
  lastDataOrder?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TableForm?: any
  isAddButtonHidden?: boolean
  filterComp?: ReactNode
  onPress?:MouseEventHandler
}

export const TableActionsUser = ({
  searchValue,
  onChangeSearchValue,
  sheetTriggerTitle,
  sheetTitle,
  lastDataOrder,
  TableForm,
  isAddButtonHidden,
  filterComp,
  onPress,
}: IProps) => {
  const [isSheetOpen, setSheetOpen] = useState(false)

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex w-full max-w-sm items-center space-x-2">
        <Input
          type="text"
          value={searchValue}
          onChange={(e) => {
            const rawValue = e.target.value
            const formattedValue = rawValue
            onChangeSearchValue?.(formattedValue)
          }}
          placeholder="Qidirish..."
        />
        <Button size="icon" onClick={onPress}>

          <Search className="size-4" />
        </Button>
      </div>

      <div className="flex gap-4">
        {filterComp}
        {!isAddButtonHidden && (
          <Button onClick={() => setSheetOpen(true)}>
            <Plus className="size-4 mr-2" />
            {sheetTriggerTitle}
          </Button>
        )}
      </div>

      <Sheet
        sheetTitle={sheetTitle}
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}>
        <TableForm lastDataOrder={lastDataOrder} setSheetOpen={setSheetOpen} />
      </Sheet>
    </div>
  )
}
