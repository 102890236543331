import { User } from "./types"

interface AdapterUser {
  _id: string
  id: string
  name: string
  photo: string | null
  title: string
  is_banned: boolean
}

export const adaptUser = (item: User): AdapterUser => ({
  _id: item._id,
  id: item.id,
  name: item.name,
  photo: item.photo ?? null,
  title: item.title,
  is_banned: item.is_banned,
})

export const adaptUsers = (data?: User[]) =>
  data?.length ? data.map((item) => adaptUser(item)) : []
