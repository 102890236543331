import { useMutation } from "@tanstack/react-query"

import { useToast } from "components/ui/use-toast"

import { SetTitleUser } from "../api"
import { UserTitleInput } from "../types"

import { queryClient } from "services/react-query"
import { showErrorToast } from "utils/showErrorToast"

interface IHook {
  id?: string
  setSheetOpen: (state: boolean) => void
}

export const useEditUser = ({ id = "", setSheetOpen }: IHook) => {
  const { toast } = useToast()

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (title: UserTitleInput) => SetTitleUser(id, title),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Titul muvaffaqiyatli berildi.",
      })
      queryClient.invalidateQueries({ queryKey: ["users_list"] })
      setSheetOpen(false)
    },
    onError: (error: Error) => showErrorToast(error),
  })

  return {
    triggerUserEdit: mutate,
    isPending,
    isSuccess,
    isError,
  }
}
